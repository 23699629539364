<template>
  <div class="main">
    <div class="columns is-centered">
      <div class="column is-7-desktop is-9-tablet">
        <h1 class="title is-1 has-text-white">Frequently Asked Questions (FAQs)</h1>
        <faqs-list></faqs-list>
      </div>
    </div>
  </div>
</template>

<script>
import FaqsList from "../components/FaqsList";
export default {
  name: "Faqs",
  components: {FaqsList}
}
</script>

<style scoped>
.main {
  background: #A4349C; background-image: radial-gradient(at 5.8% 13.9%, #8B1EC1 0px, transparent 50%),radial-gradient(at 32.6% 15.1%, hsl(339, 71%, 50%) 0px, transparent 50%);
  padding: 6.9%;
}
</style>